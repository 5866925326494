import * as React from 'react';
import Header from './header';
import Footer from './footer';
// import MenuMobile from './menu/menu-mobile';
import ScrollTopComponent from './scroll-top';
import '../../layouts/css/bootstrap-cus.css';
import '../../layouts/css/omnicasa.css';
import '../../layouts/css/responsive.css';
import '../../layouts/css/font-awesome.min.css';
import { translate } from 'react-i18next';
import '../../i18n';

interface IIndexProps {
    children: any;
}

const Layout = (props: IIndexProps) => {
    return (
        <div>
            <Header />
            {props.children}
            <Footer />
            <ScrollTopComponent />
        </div>
    );
};

export default translate('translations')(Layout);
