import * as React from 'react';
import { navigate } from 'gatsby';
import { translate } from 'react-i18next';
// import * as jQuery from 'jquery';
import * as helper from '../../../helper/helpers';

const _dataJson = require('../../../../content/layout/menu/menu.json');

interface IMobileMenuProps {
    t?: any;
    i18n?: any;
}

class MenuMobileComponent extends React.Component<IMobileMenuProps> {
    constructor(props: IMobileMenuProps) {
        super(props);
    }

    public handleMenuChange(e: any) {
        navigate(e.target.value);
    }

    public checkMenuActive(link: string) {
        if (typeof window !== 'undefined') {
            let pathname: string = window.location.pathname;
            pathname = pathname.replace(/\/$/, '').trim();
            pathname = decodeURIComponent(pathname);
            if (typeof link !== 'undefined') {
                link = link.replace(/\/$/, '').trim();
                link = decodeURIComponent(link);
                if (pathname === link) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
        return false;
    }

    public render() {
        const { i18n } = this.props;
        return (
            <select id='menu-mobile'
                style={{width: '100%'}}
                onChange={this.handleMenuChange.bind(this)}>
                {_dataJson.menu.map((item: any, index: number) => {
                    return <option key={index} value={item.link} selected={this.checkMenuActive(item.link)} >{helper.getContentWithLanguage(item, i18n.language)}</option>;
                })}
            </select>
        );
    }
}

export default translate('translations')(MenuMobileComponent);
