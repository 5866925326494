import * as React from 'react';
import { Link, StaticQuery } from 'gatsby';
import Language from './language';
import Menu from './menu/menu';
import MenuMobile from './menu/menu-mobile-select';
import Img from 'gatsby-image';
// const _dataJson = require('../../../content/layout/logo/logo.json');

interface IHeaderProps {
    siteTitle?: string;
}

class Header extends React.Component<IHeaderProps> {
    constructor(props: IHeaderProps) {
        super(props);
    }

    public getChildImageSharp(imagePath: string, allFile: any) {
        // console.log(allFile);
        const image = allFile.edges.find((item: any) => item.node.absolutePath.includes(imagePath));
        return image.node.childImageSharp;
    }

    public render() {
        return (
            <StaticQuery
                query={graphql`
                        query {
                            
                            allLogoJson {
                                edges {
                                    node {
                                        image
                                    }
                                }
                            }

                            allFile(filter:{sourceInstanceName:{eq: "images-uploads"}}) {
                                edges {
                                    node {
                                        absolutePath
                                        relativePath
                                        childImageSharp{
                                            resolutions {
                                                src
                                                srcSet
                                                base64
                                            }
                                            sizes(maxWidth: 265, maxHeight: 125) {
                                                src
                                                srcSet
                                                base64
                                            }
                                    }
                                }
                              }
                            }
                        }
                    `} render={data => {
                    // console.log(data);
                    const getChildImageSharp = this.getChildImageSharp(data.allLogoJson.edges[0].node.image, data.allFile);
                    const logo = !!data.allLogoJson.edges[0].node.image ? getChildImageSharp : {};
                    return <div id='header-row'>
                        <div className='row-container'>
                            <div className='container-fluid'>
                                <header>
                                    <div className='row-fluid'>
                                        <div id='logo' className='span3'>
                                            <div className='logo-div'>
                                                <Link to='/'>
                                                    <Img className='logo-img' sizes={logo.sizes} alt='logo' />
                                                </Link>
                                            </div>
                                        </div>
                                        <Language />
                                        <div className='navigation navigation-menu span9'>
                                            <Menu />
                                        </div>
                                        <div style={{ padding: '10px'}}><MenuMobile /></div>
                                    </div>
                                </header>
                            </div>
                        </div>
                        <div className='menushadow'></div>
                    </div>;
                }}
            />);
    }
}

export default Header;
