import * as qs from 'query-string';
import * as helpConfig from './helpers-config';
import * as i18n from 'i18next';

export function getLanguageDirectory() {
    return helpConfig.LanguageDirectory;
}

export function setSessionStorage(name: string, data: any) {
    if (typeof window !== 'undefined') {
        if (name.includes('propList')) {
            window.sessionStorage.setItem(name, JSON.stringify(data));
        } else {
            window.sessionStorage.setItem(name, data);
        }
    }
}

export function findParamQueryString(nameParam: string) {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (typeof parsed[nameParam] !== 'undefined') {
        return parsed[nameParam];
    } else {
        return '';
    }
}

export function setLanguageDefault(langNow: string) {
    let lang = null;
    if (typeof window !== 'undefined') {
        lang = window.localStorage.getItem('lang-default');
    }

    if (lang === null || lang === '') {
        handleChangeLanguage(helpConfig.LanguageDefault);
    } else {
        const langEx = helpConfig.LanguageDirectory.find((item: any) => item.code === langNow);
        if (langEx === '' || langEx === null) {
            handleChangeLanguage(helpConfig.LanguageDefault);
        }
    }
}

export function handleChangeLanguage(langChange: string) {
    i18n.changeLanguage(langChange);
    if (typeof window !== 'undefined') {
        window.localStorage.setItem('lang-default', langChange);
    }
}

export function getMarqueeImage(Marquee: number, langNow: string) {
    const marquee = helpConfig.MarqueeListImage.find((item: any) => item.ID.toString() === Marquee.toString());
    let url: string;
    if (Marquee !== 0) {
        url = `/layouts/properties-images/${langNow}/${!!marquee ? marquee.name : ''}`;
    } else {
        url = '';
    }
    return url;
}

export function getImagePropertyWithMarquee(Imgix: any, ImgixW: string, ImgixH: string, ImgMarque: string) {
    let url: string;
    if (ImgMarque !== '') {
        url = `${Imgix}?w=${ImgixW}&h=${ImgixH}&fit=crop&auto=format,compress&mark=${ImgMarque}&markalign=top,left&markx=-5&marky=-6&markw=200&markh=200`;
    } else {
        url = `${Imgix}?w=${ImgixW}&h=${ImgixH}&fit=crop&auto=format,compress`;
    }
    return url;
}

export function getNameGoal(id: string) {
    if (id === '0') {
        return 'FOR_SALE';
    } else {
        return 'FOR_RENT';
    }
}

export function CheckFieldIsset(obj: any, field?: string) {
    if (typeof field === 'undefined' || field === null || field === '') {
        if (typeof obj === 'undefined' || obj === null || field === '') {
            return false;
        } else {
            return true;
        }
    } else {
        if (typeof obj[field] !== 'undefined') {
            return true;
        } else {
            return false;
        }
    }

}

export function CheckFieldNotNull(obj: any, field: string): boolean {

    if (typeof obj === 'undefined' || obj === null || field === '') {
        return false;
    } else {
        if (typeof obj[field] !== 'undefined' && obj[field] !== null && obj[field] !== '') {
            return true;
        } else {
            return false;
        }
    }

}

export function getContentWithLanguage(obj: any, langNow: string) {
    if (typeof obj === 'undefined' || obj === null) {
        return '';
    } else if (langNow === '' || langNow === null) {
        return '';
    } else {
        if (typeof obj[langNow] !== 'undefined' && obj[langNow] !== null && obj[langNow] !== '') {
            return compareImage(obj[langNow]);
        } else {
            return '';
        }
    }
}

export function getNameWithIDinListNode(id: number, list: any): string {
    const object = list.find((item: any) => item.node.ID.toString() === id.toString());
    return object.node.Name;
}

export function compareImage(content: string) {
    const regex_image_netlify = /\!\[.*\]\(((\\|\/)[a-z0-9\s_@\-^!#$%&+={}\[\]]+)+.(gif|jpg|jpeg|tiff|png)\)/g;
    const regex_image_path = /((\\|\/)[a-z0-9\s_@\-^!#$%&+={}\[\]]+)+.(gif|jpg|jpeg|tiff|png)/g;
    const regex_image_name = /\[.*\]/g;
    const image_find = content.match(regex_image_netlify);
    const image_path = content.match(regex_image_path);
    const image_name = content.match(regex_image_name);
    let newContent: any;
    if (image_find !== null && image_path !== null && image_name !== null) {
        for (let i = 0; i < image_find.length; i++) {
            newContent = content.replace(image_find[i], `<img src='${image_path[i]}' alt='${image_name[i]}'  />`);
        }
    } else {
        newContent = content;
    }
    return newContent;
}

export function formatCurrency(amount: number) {
    try {
        let decimalCount = 0;
        const decimal = ',';
        const thousands = '.';
        const prefix = '€ ';
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';
        amount = Math.abs(Number(amount) || 0); 
        const i = (amount.toFixed(decimalCount)).toString();
        const j = (i.length > 3) ? i.length % 3 : 0;

        const result = prefix + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - parseInt(i, 10)).toFixed(decimalCount).slice(2) : '');
        return result;
    } catch (e) {
        console.log(e);
        return 'NaN';
    }
}

export function postMessageToIframe(IdElement: string, propslist: any[] ,messageInfo?: string) {
    const postObject = { propsList: propslist, messageInfo: messageInfo? messageInfo : 'MORE INFO' };
    const frame: any = document.getElementById(IdElement);
    const childWindow = frame.contentWindow;
    childWindow.postMessage(JSON.stringify(postObject), '*');
}
