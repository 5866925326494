
import * as i18n from 'i18next';
import * as LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

const en = require('./en.json') as I$;
const fr = require('./fr.json') as I$;
const nl = require('./nl.json') as I$;

// export function translate(key: keyof I$, language: 'en' | 'fr' | 'nl' | string): string {
//     switch (language.toLowerCase()) {
//         case 'fr': return fr[key];
//         case 'nl': return nl[key];
//        default: return en[key];
//   }
// }

i18n
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    resources: {
      en: {
        translations: en
      },
      nl: {
        translations: nl
      },
      fr: {
        translations: fr
      }
    },
    fallbackLng: 'nl',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    }
  });

export default i18n;

interface I$ {
  'Address': string;
  'Area Application': string;
  'Ask for a visit': string;
  'Ask for more info': string;
  'City': string;
  'CO2 Emission': string;
  'CO2_EMISSION_FORMAT': string;
  'Confort': string;
  'Construction Year': string;
  'Contact': string;
  'Description': string;
  'Divisions': string;
  'Environment Name': string;
  'Extoerior Name': string;
  'Flot Width': string;
  'For rent': string;
  'For sale': string;
  'Free Valuation': string;
  'Front Width': string;
  'Garage': string;
  'General': string;
  'Has Allotment Permit': string;
  'Has Gas': string;
  'Has Lift': string;
  'Has Permit': string;
  'Has Preemptive Rights': string;
  'Has Summons': string;
  'Has Water': string;
  'Heating Name': string;
  'Home': string;
  'Interior Name': string;
  'Judicial Decision': string;
  'Kitchen Name': string;
  'MAPS VIEW': string;
  'MARQUEE_3': string;
  'MARQUEE_4': string;
  'More Info': string;
  'New For Sale': string;
  'No': string;
  'Not set': string;
  'Number Of Bath Rooms': string;
  'Number Of Bed Rooms': string;
  'Number Of Shower Rooms': string;
  'Planning Information': string;
  'Price': string;
  'Property': string;
  'Rateable': string;
  'Search': string;
  'Services': string;
  'STREET VIEW': string;
  'Surface Ground': string;
  'Surface Living': string;
  'Surface Total': string;
  'Type': string;
  'Unique Code': string;
  'Window Frame': string;
  'Window Glazing': string;
  'Yes': string;
}
