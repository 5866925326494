import * as React from 'react';
import { Link } from 'gatsby';
import { translate } from 'react-i18next';
// import * as jQuery from 'jquery';
import * as helper from '../../../helper/helpers';

const _dataJson = require('../../../../content/layout/menu/menu.json');

interface IMobileMenuProps {
    t?: any;
    i18n?: any;
}

class MenuComponent extends React.Component<IMobileMenuProps> {
    constructor(props: IMobileMenuProps) {
        super(props);
    }

    public checkMenuActive(link: string) {
        if (typeof window !== 'undefined') {
            let pathname: string = window.location.pathname;
            pathname = pathname.replace(/\/$/, '').trim();
            pathname = decodeURIComponent(pathname);
            if (typeof link !== 'undefined') {
                link = link.replace(/\/$/, '').trim();
                link = decodeURIComponent(link);
                if (pathname === link) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
        return false;
    }

    public render() {
        const { i18n } = this.props;
        return (
            <ul id='menu' className='sf-menu sf-js-enabled sf-shadow'>
                {_dataJson.menu.map((item: any, index: number) => {
                    return <li key={index} className={`item-${index} ${this.checkMenuActive(item.link)? 'active' : ''}`}>
                        <Link to={item.link}>
                            {helper.getContentWithLanguage(item, i18n.language)}
                        </Link>
                    </li >;
                })}
            </ul>
        );
    }
}

export default translate('translations')(MenuComponent);
