import * as React from 'react';
import * as classNames from 'classnames';
import { translate } from 'react-i18next';
import * as helper from '../../helper/helpers';

interface ILanguageSwitcherProps {
    i18n: any;
}

interface ILanguageSwitcherState {
    language: string;
    firstSync: boolean;
}

class LanguageSwitcherComponent extends React.Component<ILanguageSwitcherProps, ILanguageSwitcherState> {
    constructor(props: ILanguageSwitcherProps) {
        super(props);
        const { i18n } = this.props;
        this.state = {
            language: i18n.language,
            firstSync: true
        };
    }

    public componentDidMount() {

    }

    public componentWillReceiveProps(nextProps: any) {

    }

    public renderLanguageChoice(language: any) {
        if(language.use === true) {
            const buttonClass = classNames('LanguageSwitcher__button', {
                'LanguageSwitcher__button--selected': this.state.language === language.code,
            });
    
            return (
                <a
                    key={language.ID}
                    className={buttonClass}
                    onClick={() => helper.handleChangeLanguage(language.code)}
                    style={{
                        marginRight: '10px'
                    }}
                >
                    <img src={`/layouts/lang-images/${language.code}.gif`} alt={language.title} title={language.title} />
                </a>
            );
        }
        return null;
    }

    public countLanguageUsing(languages: any) {
        let count = 0;
        for(const language of languages) {
            if(language.use === true) {
                count++;
            }
        }
        return count;
    }

    public render() {
        const languages = helper.getLanguageDirectory();
        const countLanguageUsing = this.countLanguageUsing(languages);
        if (languages.length > 1 && countLanguageUsing > 1) {
            return (
                <div className='language-switcher'>
                    {languages.map((language: any) => this.renderLanguageChoice(language))}
                </div>
            );
        } 
        return null;
    }
}
export default translate('translations')(LanguageSwitcherComponent);
