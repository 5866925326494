import * as React from 'react';
import * as helper from '../../helper/helpers';
import { translate } from 'react-i18next';
const _dataJson = require('../../../content/layout/copyright/copyright.json');
import * as FontAwesomeIcon from 'react-fontawesome';

interface IFooterProps {
    i18n?: any;
    t?: any;
}

class Footer extends React.Component<IFooterProps> {
    constructor(props: IFooterProps) {
        super(props);
    }

    public render() {
        const { i18n } = this.props;
        // const copyright = !!data.allCopyrightJson.edges[0].node? data.allCopyrightJson.edges[0].node : {};
        const copyright = _dataJson;
        return <div id='footer-wrapper'>
        <div className='footer-wrapper-inner'>
            <div id='copyright-row'>
                <div className='row-container'>
                    <div className='container-fluid'>
                        <div id='copyright' className='row-fluid'>
                            <div className='span6' dangerouslySetInnerHTML={{ __html: helper.getContentWithLanguage(copyright.content, i18n.language)}}>
                            </div>
                            <ul className='menu social'>
                                <li className='firstItem'>
                                    {copyright.connectlinks.facebook !== '' && copyright.connectlinks.facebook !== null && <a href={copyright.connectlinks.facebook} target='_blank' style={{ fontSize: '32px' }}  rel='noopener' aria-label='Facebook ImmoVanParijs' ><FontAwesomeIcon name='facebook-square' /></a>}
                                </li>
                                <li className=''>
                                    {copyright.connectlinks.instagram !== '' && copyright.connectlinks.instagram !== null && <a href={copyright.connectlinks.instagram} target='_blank' style={{ fontSize: '32px' }}  rel='noopener' aria-label='Instagram ImmoVanParijs' ><FontAwesomeIcon name='instagram' /></a>}
                                </li>
                                <li className=''>
                                    {copyright.connectlinks.twitter !== '' && copyright.connectlinks.twitter !== null && <a href={copyright.connectlinks.twitter} target='_blank' style={{ fontSize: '32px' }}  rel='noopener' aria-label='Twitter ImmoVanParijs' ><FontAwesomeIcon name='twitter' /></a>}
                                </li>
                                <li className=''>
                                    {copyright.connectlinks.googleplus !== '' && copyright.connectlinks.googleplus !== null && <a href={copyright.connectlinks.googleplus} target='_blank' style={{ fontSize: '32px' }}  rel='noopener' aria-label='Googleplus ImmoVanParijs' ><FontAwesomeIcon name='google-plus-square' /></a>}
                                </li>
                                <li className=''>
                                    {copyright.connectlinks.linkedln !== '' && copyright.connectlinks.linkedln !== null && <a href={copyright.connectlinks.linkedln} target='_blank' style={{ fontSize: '32px' }}  rel='noopener' aria-label='Linkedln ImmoVanParijs' ><FontAwesomeIcon name='linkedln-square' /></a>}
                                </li>
                                <li className='lastItem'>
                                    {copyright.connectlinks.rss !== '' && copyright.connectlinks.rss !== null && <a href={copyright.connectlinks.rss}  target='_blank' style={{ fontSize: '32px' }}  rel='noopener' aria-label='Rss ImmoVanParijs' ><FontAwesomeIcon name='rss-square' /></a>}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
    }
}

export default translate('translations')(Footer);
