import * as React from 'react';
import { translate } from 'react-i18next';
import { debounce } from 'lodash';
import * as FontAwesomeIcon from 'react-fontawesome';

interface IScrollTopProps {
    i18n?: any;
    t?: any;
}

interface IScrollTopState {
    hide: boolean;
}

class ScrollTopComponent extends React.Component<IScrollTopProps, IScrollTopState> {
    constructor(props: IScrollTopProps) {
        super(props);
        this.state = {
            hide: true,
        };
    }

    public componentDidMount() {
        window.addEventListener('scroll', debounce(() => {// lodash debounce method.
            const supportPageOffset = window.pageXOffset !== undefined;
            const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
            const scroll = {
               x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement!.scrollLeft : document.body.scrollLeft,
               y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement!.scrollTop : document.body.scrollTop
            };
        
            if(scroll.y < 10) { // 3000px (arbitrary - put whatever point you need there.)
                // const element = jQuery('#back-top'); 
                // this.backTop.css('display','none');
                this.setState({hide: true});
            }

            if(scroll.y >= 10) { // 3000px (arbitrary - put whatever point you need there.)
                // const element = jQuery('#back-top'); 
                // this.backTop.css('display','block');
                this.setState({hide: false});
            }
        }, 10));
    }

    public ScrollToTop() {
        if(typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }

    public render() {
        return (
            <div id='back-top' className={`${this.state.hide? 'hidden' : ''}`}>
                <button onClick={this.ScrollToTop.bind(this)} aria-label='Scroll to top'><FontAwesomeIcon name='arrow-circle-up' /> </button>
            </div>
        );
    }
}

export default translate('translations')(ScrollTopComponent);
